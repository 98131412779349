
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  components: {},

  setup() {
    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addBrandModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      //const db_data = {}
      await setCategoryData("");
      await setCompanyData(company.value);
    });

    const formData = ref({
      name: "",
      company_select: "",
      short_name: "",
      category_select: [],
      active: 1,
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Brand name is required",
          trigger: "change",
        },
      ],
    });

    const category = ref([]);
    const setCategoryData = async (data) => {
      //loading.value = true;
      try {
        // var values = {"search_term" : data}
        await store
          .dispatch(Actions.CUST_GET_PARENT_CATEGORY)
          .then(({ data }) => {
            category.value = data;
            console.log(category.value);
            //loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const company = ref([]);
    const setCompanyData = async (data) => {
      //loading.value = true;
      try {
        var values = { company_id: 0, page: 1, records_per_page: 10 };
        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            company.value = data.result_list;
            //loading.value = false;
            console.log(company.value);
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setProductBrandData = async (data) => {
      debugger;
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        brand_id: 0,
        brand_name: data.name,
        brand_short_name: data.short_name,
        company_id: data.company_select,
        category_id: data.category_select,
        user_id: user.user_id,
        active: 1,

        // brand_name: data.name,
        // company_id: data.company_select,
        // category_id: data.category_select,
        // created_user_id: user.user_id,
        // created_ip: "0.0.0.0",
        // created_uagent: "Test",
        // active: data.active,
      };

      await store
        .dispatch(Actions.CUST_ADD_PRODUCT_BRAND, db_data)
        .then(({ data }) => {
          debugger;
          if (data) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Product Brand has been successfully added.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addBrandModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setProductBrandData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addBrandModalRef,
      setCompanyData,
      setCategoryData,
      company,
      category,
      active_list_data,
    };
  },
});
